import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/alanmortoni/Code/Personal/qvsr/node_modules/gatsby-theme-docz/src/base/Layout.js";
import lifecycleURL from '../assets/survey-lifecycle.jpg';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1> Survey Lifecycle </h1>
    <img src={lifecycleURL} style={{
      maxWidth: '750px',
      borderRadius: '8px'
    }} alt="Survey Lifecycle" />
    <div style={{
      textAlign: 'justify'
    }}>
  A survey in QVSR will start aspilot status. Pilot allows the survey to be distributed for test purposes. Researchers
  can fully edit this survey at this stage and are able to publish this survey. Inpublished status, the researcher can
  edit the copy (text) of the survey but will not be able to change the number of credits and voting method. At any
  time, researchers can finish a published survey, finished surveys stay on the dashboard but are not accessible to
  respondents.
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      